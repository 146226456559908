.ribbon-wrapper-1 {
    width: 106px;
    height: 108px;
    overflow: hidden;
    position: absolute;
    top: -6px;
    right: -6px;
}

.ribbon-1 {
    font: bold 15px Sans-Serif;
    line-height: 18px;
    color: #333;
    text-align: center;
    text-transform: uppercase;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    position: relative;
    padding: 7px 0;
    left: -11px;
    top: 26px;
    width: 150px;
    // background-color: #3c4b64;
    background-color: #d3a325;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.5px;
}

.ribbon-1:before,
.ribbon-1:after {
    content: "";
    border-top: 4px solid #3c4b64;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    position: absolute;
    bottom: -4px;
}

.ribbon-1:before {
    left: 0;
}

.ribbon-1:after {
    right: 0;
}

.packages-main-wrapper {
    text-align: center;
    -webkit-box-shadow: 0px 0px 7px -3px rgb(60 75 100 / 66%);
    -moz-box-shadow: 0px 0px 7px -3px rgb(60 75 100 / 66%);
    box-shadow: 0px 0px 7px -3px rgb(60 75 100 / 66%);
    height: 100%;
    margin-bottom: 20px;
}

.package-header h3 {
    color: #fff;
    font-size: 24px;
}

.package-header {
    position: relative;
    padding: 10px;
    // background: rgb(60 75 100 / 66%);
    background: rgb(28 27 23);
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.package-info {
    padding: 10px;
    height: 90%;
}

.package-com-text {
    // font-family: var(--thm-font-3);
    margin-bottom: 3px;
    line-height: 21px;
}

.amount-package {
    font-size: 18px;
    color: #000;
    font-weight: 600;
    margin-bottom: 5px;
}

// .our-package-page {
//     margin-bottom: 50px;
//     padding-bottom: 50px;
// }
.packages-footer {
    position: relative;
    padding: 10px;
    // background: rgb(60 75 100 / 66%);
    background: rgb(28 27 23);
    height: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.packages-footer .thm-btn {
    padding: 7px 26px 7px;
    font-size: 16px;
    font-weight: 400;
}

html:not([dir=rtl]) .packages-footer .form-check .form-check-input {
    float: none;
}

.packages-footer .form-check {
    width: 100%;
}

.packages-footer .form-check-label {
    margin-left: 20px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

.card-footer .btn-primary {
    margin-left: 50px;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

html:not([dir=rtl]) .from-wrapper .form-select {
    padding: 1.375rem 2.25rem 0.375rem 0.75rem;
}

.mobile-number-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.closeBtnNo {
    margin-left: 10px;
    padding: 10px;
    background: transparent;
    border: 0px;
    font-size: 21px;
    margin-bottom: 20px;
}

.addNumberBtn {
    margin-left: 10px;
    padding: 10px;
    background: #321fdb;
    color: #fff;
    border: 0px;
    font-size: 14px;
    margin-bottom: 20px;
    width: fit-content;
    border-radius: 7px;
    float: right;
}

.mobile-number-wrapper .form-floating.mb-3 {
    flex: 8;
}

.no-record-found-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.no-record-found-wrapper h3 {
    font-size: 32px;
    color: #000;
    font-family: var(--thm-font-3);
}

.scannerImg {
    width: 100px;
    height: 100px;
}

.input-wrapper-default {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
    border: var(--cui-border-width) solid var(--cui-input-border-color, var(--cui-border-color));
    border-radius: var(--cui-border-radius);
    position: relative;
    padding-left: 15px;
    padding-right: 15px;
}

.input-wrapper-default .form-label {
    position: absolute;
    background-color: var(--cui-body-bg);
    border-radius: var(--cui-border-radius);
    color: rgba(var(--cui-body-color-rgb), 0.65);
    height: 25px;
    top: 0;
    font-size: 13px;
    padding: 5px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.time-picker-show {
    width: 100%;
}

.icon-close {
    font-size: 18px;
    color: #f00;
    background: transparent;
    border: 0px;
}

.uploade-img-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
}

.close-btn-img {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    cursor: pointer;
}

.close-btn-img span {
    background: #f00;
    color: #fff;
    font-size: 20px;
    border-radius: 50%;
    padding: 5px;
    width: 25px;
    height: 25px;
    display: block;
    line-height: 10px;
    font-weight: 600;
    -webkit-box-shadow: 0px 0px 7px -3px rgb(255 145 0 / 75%);
    -moz-box-shadow: 0px 0px 7px -3px rgb(255 145 0 / 75%);
    box-shadow: 0px 0px 7px -3px rgb(255 145 0 / 75%);
}

.uploaded-img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #766f6f;
    border-radius: 5px;
}

.uploade-sub-img-wrapper {
    position: relative;
    margin-right: 15px;
    margin-bottom: 15px;
}

.page-title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 25px;
    color: var(--cui-sidebar-nav-link-color);
}

// .contact-main-wrapper .icon-close {
//     float: right;
// }
.uploade-img-wrapper .form-floating.mb-3 {
    width: 100%;
}

.packages-footer .form-check {
    cursor: pointer;
}

.mr-1 {
    margin-right: 10px;
}

.sidebar-brand {
    background: #1c1b17;
}

.sidebar-toggler,
.simplebar-content-wrapper,
.sidebar-nav {
    background: #1c1b17;
}

.sidebar-nav .nav-link.active {
    color: #f5f6f6;
    background: rgb(255 255 255 / 41%);
}

.sidebar-nav .nav-link,
.sidebar-nav .nav-icon {
    color: #f5f6f6;
}

.shop-details-text span,
.show-info-text span {
    color: #2b3748ae;
    font-weight: 500;
    font-size: 15px;
    display: block;
}

.shop-details-text span img,
.show-info-text span img {
    width: 150px;
    height: 100px;
}

.shop-details-text span .company-logo-shop {
    width: 100px;
    height: 100px;
}

.shop-details-text,
.show-info-text {
    margin-bottom: 20px;
}

.shop-details-text label,
.show-info-text label {
    color: #000;
    font-weight: 600;
    font-size: 15px;
    display: block;
    margin-bottom: 8px;
}

.shop-details-text .shop-images-details {
    width: 235px;
    height: 200px;
    object-fit: cover;
    border: 1px solid #766f6f;
    border-radius: 5px;
}

.shop-details-img-wrapper {
    display: flex;
    width: 100%;
    grid-gap: 10px;
    flex-wrap: wrap;
}

.overlay-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Adjust the transparency as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.overlay {
    position: relative;
    z-index: 99999;
}

// .our-package-page .table-responsive {
//     display: flex;
//     grid-gap: 15px;
// }
.our-package-page .col-xl-4a.col-lg-4a.col-sm-6a {
    width: 100%;
    height: 100%;
    min-height: 100%;
}

.our-package-page table tr {
    display: flex;
}

@media only screen and (max-width: 768px) {
    .our-package-page .col-xl-4a.col-lg-4a.col-sm-6a {
        width: max-content;
        max-width: 335px;
    }
    .card.text-white.bg-primary.py-5 {
        width: 100% !important;
    }
}